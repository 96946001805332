// Styling for the masthead
header.masthead {
  text-align: center;
  color: white;
  background-image: url("../img/header-bg.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  .intro-text {
    padding-top: 150px;
    padding-bottom: 100px;
    .intro-lead-in {
      font-size: 22px;
      font-style: italic;
      line-height: 22px;
      margin-bottom: 25px;
      @include serif-font;
    }
    .intro-heading {
      font-size: 37px;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 10px;
      @include heading-font;
    }
    .intro-heading-sub {
      font-weight: 600;
      margin-bottom: 15px;
      @include heading-font;
    }
    h1.intro-heading-sub {
      font-size: 20px;
    }
    h2.intro-heading-sub {
      font-size: 15px;
    }
  }
}
header.section2 {
  display: none;
}
header.section3 {
  display: none;
}

@media (min-width: 768px) {
  header.masthead {
    .intro-text {
      padding-top: 300px;
      padding-bottom: 200px;
      .intro-lead-in {
        font-size: 40px;
        font-style: italic;
        line-height: 40px;
        margin-bottom: 25px;
        @include serif-font;
      }
      .intro-heading {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 10px;
        @include heading-font;
      }
      .intro-heading-sub {
        font-weight: 600;
        @include heading-font;
      }
      h1.intro-heading-sub {
        font-size: 30px;
        margin-bottom: 30px;
      }
      h2.intro-heading-sub {
        font-size: 20px;
        margin-bottom: 30px;
      }
    }
  }
  header.section2 {
    display: block;
    background-image: url("../img/services-avantages.jpg");
    background-position: top center;
  }
  header.section3 {
    display: block;
    background-image: url("../img/avantages-economies.jpg");
  }
}
