#savings {
  .title-salary {
    font-family: "Lucida Sans";
  }

  .savings-details {
    margin-bottom: 50px;
    text-align: center;
    h4 {
      margin-top: 10px;
      margin-bottom: 0;
      text-transform: none;
    }
    p {
      margin-top: 0;
    }
  }

  p.year-savings {
    font-size: 2em;
  }

  .savings-price {
    color: green;
  }

  .savings-price:before {
    content: "$";
  }

  div.row.savings-gauges {
    margin-bottom: 50px;
  }
}
