// Styling for the avantages section
#avantages {
  div.label-image {
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
  }

  h3.section-title {
    margin-bottom: 20px;
    margin-top: 40px;
  }

  div.row {
    display: flex;
    flex-direction: row;
    text-align: center;
    div.label-image {
      order: 1;
    }
    div.label {
      order: 2;
    }
  }
  div.inverted {
    div.label-image {
      order: 1;
    }
    div.label {
      order: 2;
    }
  }
  @media (min-width: 992px) {
    div.label-image {
      margin: 0;
    }
    div.row {
      div.label {
        text-align: left !important;
      }
    }
    div.inverted {
      div.label-image {
        order: 2;
      }
      div.label {
        text-align: right !important;
        order: 1;
      }
    }
  }
}
