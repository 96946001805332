// Styling for the contact section
section#contact {
  padding: 10px 0;
  //background-color: $primary-background;
  //background-image: url('../img/map-image.png');
  //background-repeat: no-repeat;
  //background-position: center;
  div.contact-coordonates {
    margin: 50px 0;
    div.contact-coordonates-title {
      font-weight: 600;
    }
  }
  .form-group {
    margin-bottom: 15px;
    input,
    textarea {
      padding: 5px;
    }
    input.form-control {
      height: auto;
    }
    textarea.form-control {
      height: 248px;
    }
  }
  .form-control:focus {
    border-color: $primary;
    box-shadow: none;
  }
  ::-webkit-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  :-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  ::-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  :-ms-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
}
